<template>
  <div v-frag>
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-4">mdi-file-account-outline</v-icon>
        <span class="subtitle-1">{{ $t('billingAddresses') }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6">
        <v-row justify="end" class="ma-0">
          <v-col cols="12" sm="6" class="pa-0">
            <v-text-field
              v-model="search"
              :aria-label="$t('search')"
              :label="$t('search')"
              filled
              prepend-inner-icon="mdi-magnify"
              persistent-hint
              clearable
              :hint="$t('inputMessages.searchHint')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="addresses"
          :search="search"
          :loading="loadingData"
          :loading-text="$t('loadingText')"
          no-data-text="Nem található szállítási cím"
          :no-results-text="`A keresett ${search} kifejezés nem található`"
        >
          <template v-slot:item.btn="{item}">
            <v-btn text color="primary" @click="openAddressModal(item)">
              {{ $t('button.view') }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <!--<v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="openAddressModal(addressPrototype, true)">
          {{ $t('button.newAddress') }}
        </v-btn>
      </v-card-actions>-->
    </v-card>
    <v-dialog v-model="addressModal.visible" max-width="800" persistent>
      <address-details :address.sync="addressModal.addressItem" v-on:close-dialog="closeAddressModal" :is-editable="addressModal.isEditable"></address-details>
    </v-dialog>
  </div>

</template>

<script>
  import AddressDetails from './AddressDetails.vue';

  export default {
    name: 'BillingInformation',
    components: {
      AddressDetails,
    },
    props: {
      //
    },
    async beforeMount() {
      await this.loadTableData();
      if (this.addresses.length > 0) {
        this.addresses = this.deleteIdenticalAddresses(this.addresses);
      }
      // setTimeout(this.loadTableData, 1500);
    },
    data() {
      return {
        search: null,
        headers: [
          { text: this.$t('postalCode'), value: 'zipCode' },
          { text: this.$t('city'), value: 'city' },
          { text: this.$t('publicPlaceName'), value: 'publicPlaceNameNr' },
          { text: this.$t('billingName'), value: 'billingName' },
          { text: '', value: 'btn' },
        ],
        desserts: [],
        addresses: [],
        loadingData: false,
        addressModal: {
          visible: false,
          addressItem: {},
          isEditable: false,
        },
        addressPrototype: {
          id: null,
          addressType: {
            id: 2,
            lang: 'hu',
            name: 'Számlázási cím',
          },
          billingName: '',
          countryCode: 'HUN',
          countryLabel: 'Magyarország',
          zipCode: '',
          city: '',
          publicPlaceName: '',
          publicPlaceType: '',
          houseNumber: '',
          otherAddressInfo: '',
          taxNumber: '',
        },
      };
    },
    methods: {
      loadTableData() {
        if (this.$store.state.authModule.user.addresses) {
          if (this.$store.state.authModule.user.addresses.length > 0) {
            this.$store.state.authModule.user.addresses.forEach(item => {
              if (item.addressType.id === 2) {
                if (item.billingName === "" ) {
                  item.billingName = `${this.$store.state.authModule.user.profile.lastName} ${this.$store.state.authModule.user.profile.firstName}`;
                }
                item.publicPlaceNameNr = `${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
                item.zipCode = item.zipCode.toString();
                this.addresses.push(item);
              }
            });
          } else {
            this.addresses = [];
          }
        } else {
          this.addresses = [];
        }
        this.loadingData = false;
      },
      deleteIdenticalAddresses(array) {
        const goodArray = array;
        // console.log('eredeti:');
        // console.table(goodArray);
        // console.log('START --------------');
        goodArray.sort((a, b) => ((a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1));
        for (let a = 0; a < goodArray.length; a++) {
          const item = goodArray[a];
          // console.log(`${a}. item: `, item);
          if (item) {
            // console.log(`${a}. item.isDefault: `, item.isDefault);
            for (let b = a + 1; b < goodArray.length; b++) {
              const item2 = goodArray[b];
              // console.log(`${b}. item2: `, item2);
              if (item2) {
                // console.log(`${b}. item2.isDefault: `, item2.isDefault);
                if (item.billingName === item2.billingName
                  && item.city === item2.city
                  && item.houseNumber === item2.houseNumber
                  && item.publicPlaceName === item2.publicPlaceName
                  && item.publicPlaceType === item2.publicPlaceType
                  && item.zipCode === item2.zipCode) {
                  // console.log(`${a}. és ${b}. megegyezik - Törlés ${b}.`);
                  delete goodArray[b];
                }
              }
            }
          }
        }
        // console.log('END --------------');
        const goodArrayFiltered = goodArray.filter((e) => e != null);
        // console.log('átalakított:');
        // console.table(goodArrayFiltered);
        // console.log('%cend delete', 'color: #FFFFFF; font-size: 12px; background: #6c5ce7;');
        return goodArrayFiltered;
      },
      openAddressModal(addressItem, isEditable = false) {
        this.addressModal.addressItem = addressItem;
        this.addressModal.isEditable = isEditable;
        this.addressModal.visible = true;
      },
      closeAddressModal() {
        this.addressModal.visible = false;
      },
    },
  };
</script>

<style scoped>

</style>
