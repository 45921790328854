<template>
  <div v-frag>
    <template v-if="isLoading">
      <v-container class="fill-height">
        <v-row class="fill-height" align-content="center" justify="center" align="center">
          <v-progress-circular size="120" color="primary" indeterminate></v-progress-circular>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-container>
        <v-row>
          <v-col cols="12">
            <shipping-information></shipping-information>
          </v-col>
          <v-col cols="12">
            <billing-information></billing-information>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import ShippingInformation from '../../components/shipping/ShippingInformation.vue';
import BillingInformation from '../../components/shipping/BillingInformation.vue';

export default {
  name: 'InnerAddressPage',
  components: {
    ShippingInformation, BillingInformation,
  },
  props: {
    //
  },
  async beforeMount() {
    await this.$store.dispatch('GetAddresses');
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
